import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["mobile_menu", "dropdown", "dropdownMenu"]

  connect() {
    // Add click outside listener
    document.addEventListener('click', this.closeDropdownOutside.bind(this))
  }

  disconnect() {
    document.removeEventListener('click', this.closeDropdownOutside.bind(this))
  }

  toggleMobileMenu(event) {
    event.preventDefault()
    this.mobile_menuTarget.classList.toggle('hidden')
  }

  toggleDropdown(event) {
    event.preventDefault()
    event.stopPropagation()
    this.dropdownMenuTarget.classList.toggle('hidden')
  }

  closeDropdownOutside(event) {
    if (this.hasDropdownTarget && !this.dropdownTarget.contains(event.target)) {
      this.dropdownMenuTarget.classList.add('hidden')
    }
  }
}